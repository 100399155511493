import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "canvas" ]

  static values = {
    path: String
  }

  connect() {
    this.draw()
  }

  draw() {
    fetch(this.pathValue, {
    }).then(
      response => response.text()
    ).then(html => {
      this.canvasTarget.innerHTML = ''
      this.canvasTarget.insertAdjacentHTML('afterbegin', html)
    })
  }
}
