import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "canvas" ]


  connect() {
  }

  draw() {
    this.canvasTarget.innerHTML = ''
    this.canvasTarget.insertAdjacentHTML('afterbegin', event.detail[2].response)
  }
}
