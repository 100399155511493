import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "observerCanvas" ]

  connect() {
  }

  observeBeginning() {
    this.formTarget.style.display = 'none'

    this.observerCanvasTarget.innerHTML = event.detail[2].response
  }

  displayForm() {
    this.formTarget.style.display = 'block'
  }
}
