import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import { Japanese } from "flatpickr/dist/l10n/ja"

export default class extends Controller {
  static targets = [ 'selector', 'startOn', 'endOn' ]

  connect() {
    let config = {
      mode: 'range',
      locale: Japanese, dateFormat: "Y-m-d",
      onClose: (selectedDates, dateStr, instance) => {
        this.setDates(selectedDates)
      }
    }
    flatpickr(this.selectorTarget, config)
  }

  setDates(dates) {
    this.startOnTarget.value = dates[0].toLocaleDateString('sv-SE')
    this.endOnTarget.value = dates[1].toLocaleDateString('sv-SE')
  }
}
