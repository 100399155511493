import { Controller } from "stimulus"
import { Chart } from "chart.js/auto"

export default class extends Controller {
  static targets = [ 'canvas' ]

  static values = {
    params: Object
  }

  connect() {
    let chart  = new Chart(
      this.canvasTarget.getContext('2d'),
      this.paramsValue
    )
  }
}
