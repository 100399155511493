import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import { Japanese } from "flatpickr/dist/l10n/ja"

export default class extends Controller {
  static targets = [ 'selector', 'gteq', 'lteq' ]

  connect() {
    this.element.insertAdjacentHTML('afterbegin', '<input type="hidden" name="q[' + this.name() + '_gteq]" data-ransack-range-target="gteq" />')
    this.element.insertAdjacentHTML('afterbegin', '<input type="hidden" name="q[' + this.name() + '_lteq]" data-ransack-range-target="lteq" />')

    let config = {
      mode: 'range',
      defaultDate: this.selectorTarget.value,
      locale: Japanese, dateFormat: "Y-m-d",
      onReady: (selectedDates, dateStr, instance) => {
        this.setDates(selectedDates)
      },
      onClose: (selectedDates, dateStr, instance) => {
        this.setDates(selectedDates)
      }
    }
    flatpickr(this.selectorTarget, config)
  }

  setDates(dates) {
    if (dates[0]) {
      this.gteqTarget.value = dates[0].toLocaleDateString('sv-SE') + ' 00:00:00'
      if (dates[1]) {
        this.lteqTarget.value = dates[1].toLocaleDateString('sv-SE') + ' 23:59:59'
      } else {
        this.lteqTarget.value = dates[0].toLocaleDateString('sv-SE') + ' 23:59:59'
      }
    }
  }

  name() {
    return this.selectorTarget.getAttribute('name')
  }
}
