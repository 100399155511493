import { Controller } from "stimulus"
import * as lib from 'javascripts/concerns'

export default class extends Controller {
  static targets = []

  static values = {
    dom: String,
    path: String
  }

  connect() {
  }

  replace() {
    let toast = true
    if (event.target.dataset.replaceWith == 'untoast') {
      toast = false
    }

    fetch(this.pathValue, {
    }).then(
      response => response.text()
    ).then(html => {
      this.element.replaceWith(this.toDom(html))

      if (toast) {
        lib.addToast({
          state: 'success',
          title: '更新しました'
        })
      }
    })
  }

  toDom(str) {
    let dom = document.createElement(this.domValue)
    dom.innerHTML = str
    return dom.firstElementChild
  }
}
