import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  static values = {
    path: String,
    interval: Number
  }

  connect() {
    if (this.hasIntervalValue) {
      setTimeout(() => {
        this.load()
      }, this.intervalValue)
    } else {
      console.log('not found refresh interverl.')
    }
  }

  load() {
    fetch(this.pathValue)
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }
}
