import { Toast } from 'bootstrap'

export function addToast(data) {
  if (!data || !data.title || !data.state) {
    console.log('data.titleまたはdata.stateが存在しないためtoastしない')
    return
  }
  if (!data.delay) {
    data.delay = 10000
  }

  let strong = document.createElement('strong')
  strong.classList.add('me-auto', 'text-' + data.state)
  strong.appendChild(document.createTextNode(data.title))

  let button = document.createElement('button')
  button.classList.add('btn-close')
  button.setAttribute('type', 'button')
  button.setAttribute('data-bs-dismiss', 'toast')

  let header = document.createElement('div')
  header.classList.add('toast-header')

  header.appendChild(strong)
  header.appendChild(button)

  let toast = document.createElement('div')
  toast.classList.add('toast', 'toast-' + data.state)
  toast.setAttribute('data-delay', data.delay)

  toast.appendChild(header)

  if (data.message) {
    let body = document.createElement('div')
    body.classList.add('toast-body', 'text-muted')
    body.innerHTML = data.message.replace(/\r?\n/g, '<br />')

    toast.appendChild(body)
  }

  const wrapper = document.getElementById('toast-canvas')
  wrapper.appendChild(toast)

  const toaster = new Toast(toast)
  toaster.show()
}
