import { Controller } from "stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
    this.canvas = document.createElement('div')
    this.canvas.classList.add('modal-canvas')
    this.element.appendChild(this.canvas)
  }

  open() {
    this.href = event.target.getAttribute('href')
    this.canvas.innerHTML = event.detail[2].response

    this.modal = new bootstrap.Modal(this.modalTarget)
    this.modalTarget.addEventListener('hidden.bs.modal',  event => {
      this.canvas.innerHTML = ''
    })
    this.modal.show()
  }

  reopen() {
    fetch(this.href, {
    }).then(
      response => response.text()
    ).then(html => {
      this.close()

      this.canvas.innerHTML = html

      this.modal = new bootstrap.Modal(this.modalTarget)
      this.modal.show()
    })
  }

  close() {
    this.modal.hide()
    this.modal.dispose()
  }

  redirect() {
    if (!event.detail) {
      return
    }
    if (!event.detail[0].location) {
      return
    }

    window.location.href = event.detail[0].location
  }
}
